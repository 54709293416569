import React from 'react';

import { Button, Text, CheckboxGroup, Checkbox } from 'vision.kit';
import Form from '../../common/Form';
import { Apps } from '../../models/Apps';

import './OrganizationClaimsForm.scss';
import { Organization } from '../../models/organizations/Organization';
import { OrganizationClaims } from '../../models/OrganizationClaims';

interface OrganizationClaimsFormProps {
    organization: Organization;
    save: (payload: any) => void;
}

const OrganizationClaimsForm = ({ organization, save }: OrganizationClaimsFormProps) => {
    const initialValues = {
        apps_access: Apps.filter(a => organization.apps_access.includes(a.id)).map(a => a.id),
        apps_claims: organization.apps_claims
    };

    return (
        <div className='organization-claims-form'>
            <Text>Настройка прав организации</Text>
            <Form initialValues={initialValues} onSubmit={save}>
                {(formProps: any) => (
                    <div>
                        <CheckboxGroup id={'apps_access'}
                                       label={'Доступ к приложениям'}
                                       {...formProps}
                        >
                            {
                                Apps.map(app => (
                                    <Checkbox key={app.id}
                                              id={app.id}
                                              label={app.title}
                                    />
                                ))
                            }
                        </CheckboxGroup>

                        <CheckboxGroup id={'apps_claims'}
                                       label={'Права доступа'}
                                       {...formProps}
                        >
                            {
                                OrganizationClaims.map(app => (
                                    <div key={app.id} className='employee-form-access-block'>
                                        <Text>{app.title}</Text>
                                        {app.claims.map(claim => (
                                            <Checkbox key={claim.id}
                                                      id={claim.id}
                                                      label={claim.title}
                                                      className='employee-form-access-checkbox'
                                            />
                                        ))}
                                    </div>
                                ))
                            }
                        </CheckboxGroup>

                        <Button type={'submit'}>Сохранить</Button>
                    </div>
                )}
            </Form>
        </div>
    );
};

export default OrganizationClaimsForm;