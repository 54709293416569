import React from 'react';
import { H4 } from 'vision.kit'
import Page from '../../common/Page';
import { callApi, useApi } from '../../api';
import EmployeeForm from '../../components/employees/EmployeeForm';
import { Apps } from '../../models/Apps';

const EmployeeEditScreen = ({ match, history }: any) => {
    const id = match.params.id;

    const [ loading, employee ] = useApi(`employees/${id}`);
    const claims = Apps;

    const save = async (payload: any) => {
        await callApi(`employees/${id}`, 'POST', payload);
        history.push(`/employees/${id}`);
    };

    return (
        <Page>
            <H4>Редактирование сотрудника</H4>

            {!loading && claims && <EmployeeForm employee={employee} apps={claims} save={save}/>}
        </Page>
    );
};

export default EmployeeEditScreen;