import React from 'react';
import Page from '../../common/Page';
import { useApi } from '../../api';
import OrganizationCard from '../../components/organizations/OrganizationCard';

const OrganizationScreen = ({ match }: any) => {
    const id = match.params.id;
    const [ loading, organization ] = useApi(`organizations/${id}`);
    const [ loadingEmployees, employees ] = useApi(`employees?organizationId=${id}`);

    return (
        <Page>
            {!loading && !loadingEmployees && <OrganizationCard employees={employees} organization={organization}/>}
        </Page>
    )
};

export default OrganizationScreen;