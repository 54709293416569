import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { NotificationsContextProvider } from 'vision.kit';

import Routes from './Routes';

import './App.scss';

const App = () => {
    return (
        <NotificationsContextProvider>
            <Router basename={'/structure'}>
                <Routes/>
            </Router>
        </NotificationsContextProvider>
    );
};

export default App;