import React from 'react';
import { Link } from 'react-router-dom';
import { Description, H4, IconButton, Text } from 'vision.kit';

import EmployeeAvatar from './EmployeeAvatar';

import EditIcon from '../../icons/EditIcon';
import DeleteIcon from '../../icons/DeleteIcon';
import './EmployeeProfile.scss';
import { Employee } from '../../models/employees/Employee';
import { isAdmin } from '../../utils/access/isAdmin';

interface EmployeeProfileProps {
    employee: Employee;
    remove: () => void;
}

const EmployeeProfile = ({ employee, remove }: EmployeeProfileProps) => {
    return (
        <div>
            <div className='employee-profile-head'>
                <div className='employee-profile-avatar'>
                    <EmployeeAvatar size={'l'}
                                    employee={employee}
                    />
                </div>

                <div className='employee-profile-data'>
                    <div>
                        <H4>{employee.surname} {employee.name}</H4>
                        <Description>{employee.position}</Description>
                    </div>

                    {isAdmin() && <div className='employee-profile-actions'>
                        <IconButton container={<Link to={`/employees/${employee.id}/edit`}/>}
                                    icon={EditIcon}
                                    size={'s'}
                                    shape={'rounded'}
                                    className={'employee-profile-action'}
                        />

                        <IconButton icon={DeleteIcon}
                                    size={'s'}
                                    shape={'rounded'}
                                    onClick={remove}
                                    className={'employee-profile-action'}
                        />
                    </div>}
                </div>
            </div>

            <div>
                <Text>Контакты</Text>

                <div>
                    {employee.phones.map(phone => (
                        <Text key={phone.value}>{phone.value}</Text>
                    ))}
                </div>

                <div>
                    {employee.emails.map(email => (
                        <Text key={email.value}>{email.value}</Text>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default EmployeeProfile;