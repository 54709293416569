import { SelectableActions } from './SelectableActions';

const setItem = (state: any, payload: any) => {
    const item = {
        ...payload,
        selected: state.selected.filter((s: any) => s === payload.id) > 0,
        rect: null
    };

    if (state.groupX && state.groupY) {
        item.rect = {
            left: item.x - state.groupX,
            right: item.x - state.groupX + item.width,
            top: item.y - state.groupY,
            bottom: item.y - state.groupY + item.height
        };
    }
    state.items[payload.id] = item;

    return state;
};

const loadItem = (state: any, payload: any) => {
    const result = state.items[payload.id];
    if (result) {
        return state;
    }

    return setItem(state, payload);
};

const reloadItem = (state: any, payload: any) => {
    return setItem(state, payload);
};

const evaluateItemRect = (state: any) => {

    for (let key in state.items) {
        const item = state.items[key];
        item.rect = {
            left: item.x - state.groupX,
            right: item.x - state.groupX + item.width,
            top: item.y - state.groupY,
            bottom: item.y - state.groupY + item.height
        };
    }
    return state;
};

const loadGroup = (state: any, payload: any) => {
    state.groupX = payload.x;
    state.groupY = payload.y;

    return evaluateItemRect(state);
};

const intersectRect = (r1: any, r2: any) => {
    return !(
        r2.left   > r1.right  ||
        r2.top    > r1.bottom ||
        r2.right  < r1.left   ||
        r2.bottom < r1.top
    );
};

const calculateSelecting = (state: any, x: any, y: any) => {
    const selecting = [];
    const selectionRect = {
        left: state.startX < x ? state.startX : x,
        right: state.startX < x ? x : state.startX,
        top: state.startY < y ? state.startY : y,
        bottom: state.startY < y ? y : state.startY
    };

    for (let key in state.items) {
        const item = state.items[key];
        if (intersectRect(selectionRect, item.rect) && selecting.filter(s => s === item.id).length === 0) {
            selecting.push(item.id);
        } else if (selecting.filter(s => s === item.id).length > 0) {
            selecting.splice(state.selected.indexOf(item.id), 1);
        }
    }

    return selecting;
};

const calculatedDeselected = (state: any, x: any, y: any) => {
    const deselected: any[] = [];
    const selectionRect = {
        left: state.startX < x ? state.startX : x,
        right: state.startX < x ? x : state.startX,
        top: state.startY < y ? state.startY : y,
        bottom: state.startY < y ? y : state.startY
    };

    state.selected.forEach((id: any) => {
        const item = state.items[id];

        if (item) {
            if (intersectRect(selectionRect, item.rect) && deselected.filter(s => s === item.id).length === 0) {
                deselected.push(item.id);
            } else if (deselected.filter(s => s === item.id).length > 0) {
                deselected.splice(state.selected.indexOf(item.id), 1);
            }
        }
    });

    return deselected;
};

const removeDeselected = (selected: any, deselected: any) => {
    deselected.forEach((d: any) => {
        selected.splice(selected.indexOf(d), 1);
    });

    return selected;
};

const mergeArrays = (arr1: any, arr2: any) => {
    arr2.forEach((item: any) => {
        if (arr1.indexOf(item) < 0) {
            arr1.push(item);
        }
    });
    return arr1;
};

const onItemClick = (selectedItems: any, id: any) => {
    const index = selectedItems.indexOf(id);
    const deselected = [];
    if (index < 0) {
        selectedItems.push(id);
    } else {
        selectedItems.splice(index, 1);
        deselected.push(id);
    }

    return { selectedItems: selectedItems, deselected: deselected };
};

const isDeselectionStarted = (state: any, x: any, y: any) => {
    for (let key in state.items) {
        const item = state.items[key];
        if (x >= item.rect.left && x <= item.rect.right && y >= item.rect.top && y <= item.rect.bottom) {
            return state.selected.indexOf(item.id) > -1;
        }
    }

    return false;
};

const selectEnd = (state: any) => {
    if (!state.started) {
        return state;
    }

    const selected = mergeArrays(state.selected, state.selecting);
    if (state.onSelected) {
        state.onSelected(selected);
    }
    if (state.onDeselected && state.deselected.length > 0) {
        state.onDeselected(state.deselected);
    }

    return {
        ...state,
        selected: selected,
        deselected: [],
        selecting: [],
        started: false
    };
};

const SelectableReducer = (state: any, action: any) => {
    switch (action.type) {
        case SelectableActions.SelectStart:
            const x = action.payload.startX - state.groupX;
            const y = action.payload.startY - state.groupY;
            return {
                ...state,
                started: true,
                deselection: isDeselectionStarted(state, x, y),
                selecting: [],
                startX: x,
                startY: y
            };
        case SelectableActions.Selection:
            if (state.deselection) {
                const deselected = calculatedDeselected(state, action.payload.x - state.groupX, action.payload.y - state.groupY);

                return {
                    ...state,
                    deselected: mergeArrays(state.deselected, deselected),
                    selected: removeDeselected(state.selected, deselected)
                };
            }

            return {
                ...state,
                selecting: calculateSelecting(state, action.payload.x - state.groupX, action.payload.y - state.groupY)
            };
        case SelectableActions.SelectEnd:
            return selectEnd(state);
        case SelectableActions.ItemClick:
            if (state.selecting) {
                state = selectEnd(state);
            }

            const { selectedItems, deselected } = onItemClick(state.selected, action.payload.id);

            if (state.onSelected) {
                state.onSelected(selectedItems);
            }
            if (state.onDeselected && deselected.length > 0) {
                state.onDeselected(deselected);
            }

            return {
                ...state,
                selected: selectedItems,
                deselected: deselected
            };
        case SelectableActions.UnloadItem:
            delete state.items[action.payload.id];
            return state;
        case SelectableActions.ItemReload:
            return reloadItem(state, action.payload);
        case SelectableActions.GroupLoaded:
            return loadGroup(state, action.payload);
        case SelectableActions.ItemLoaded:
            return loadItem(state, action.payload);
        default:
            return state;
    }
};

export default SelectableReducer;