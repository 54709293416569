import React, { useEffect, useReducer, useRef } from 'react';
import classnames from 'classnames';

import SelectableReducer from './SelectableReducer';
import SelectableContext from './SelectableContext';

import './SelectableGroup.scss';

const SelectableGroup = ({
                             initialState = [],
                             items,
                             className,
                             children,
                             onSelected,
                             onDeselected
                         }: any) => {
    const [ state, dispatch ] = useReducer(SelectableReducer, {
        selected: initialState,
        selecting: [],
        deselected: [],
        items: {},
        onSelected: onSelected,
        onDeselected: onDeselected
    });

    const groupRef: any = useRef(null);

    useEffect(() => {
        if (groupRef) {
            dispatch({ type: 'group-loaded', payload: { x: groupRef.current.offsetLeft, y: groupRef.current.offsetTop } });
        }
    }, [ groupRef ]);

    useEffect(() => {
        if (items && items.length > 0 && Object.keys(state.items).length > 0) {
            for (let key in state.items) {
                const result = items.filter((item: any) => item.id === key);
                if (result.length === 0) {
                    dispatch({ type: 'unload-item', payload: { id: key } });
                }
            }
        }
    }, [ state.items, items ]);

    const contextValue = {
        state: state,
        dispatch: dispatch
    };

    const handleMouseDown = (e: any) => {
        dispatch({ type: 'select-start', payload: { startX: e.pageX, startY: e.pageY } });
    };

    const handleMouseUp = (e: any) => {
        dispatch({ type: 'select-end' });
    };

    const handleMouseMove = (e: any) => {
        if (state.started) {
            dispatch({ type: 'selection', payload: { x: e.pageX, y: e.pageY } })
        }
    };

    const handleMouseLeave = () => {
        dispatch({ type: 'select-end' });
    };

    return (
        <div ref={groupRef}
             className={classnames(
                 className,
                 'selectable-group'
             )}
             onMouseDown={handleMouseDown}
             onMouseMove={handleMouseMove}
             onMouseUp={handleMouseUp}
             onMouseLeave={handleMouseLeave}
        >
            <SelectableContext.Provider value={contextValue}>
                {children}
            </SelectableContext.Provider>
        </div>
    );
};

export default SelectableGroup;