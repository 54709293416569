import React from 'react';
import { getUserClaims } from '../utils/getUserClaims';

interface ProtectedComponentProps {
    claims: string[];
    children: any;
}

const ProtectedComponent = ({ claims, children }: ProtectedComponentProps) => {
    let userClaims = getUserClaims();
    let claimsIntersection = userClaims.filter(value => claims.includes(value));
    if (!claimsIntersection || claimsIntersection.length === 0) {
        return null;
    }

    return children;
};

export default ProtectedComponent;