import React from 'react';
import Form from '../../common/Form';

import { Input, Button, IconButton, CheckboxGroup, Checkbox, Text } from 'vision.kit';

import './EmployeeForm.scss';
import FormArray from '../../common/FormArray';
import CloseIcon from '../../icons/CloseIcon';
import { Employee } from '../../models/employees/Employee';
import { App } from '../../models/App';
import { getUserProfile } from '../../utils/getUserProfile';

interface EmployeeFormProps {
    employee?: Employee;
    apps?: App[];
    save: (payload: any) => void;
}

const EmployeeForm = ({ employee, apps, save }: EmployeeFormProps) => {
    const emptyForm = {
        surname: '',
        name: '',
        position: '',
        phones: [
            {
                value: ''
            }
        ],
        emails: [
            {
                value: ''
            }
        ],
        claims: []
    };

    const phoneInitialValue = {
        value: ''
    };

    const initialValues = employee ? employee : emptyForm;
    let profile = getUserProfile();
    let accessApps = profile.organization_apps_access || [];

    return (
        <div className='employee-form'>
            <Form initialValues={initialValues} onSubmit={save}>
                {(formProps: any) => (
                    <div>
                        <Input id={'surname'} label={'Фамилия'} {...formProps}/>
                        <Input id={'name'} label={'Имя'} {...formProps}/>
                        <Input id={'position'} label={'Должность'} {...formProps}/>

                        <div className='employee-form-array'>
                            <FormArray id={'phones'}
                                       addButton={<Button type={'button'}
                                                          size={'s'}
                                       >
                                           <p>Добавить</p>
                                       </Button>}
                                       arrayInitialValues={phoneInitialValue}
                                       {...formProps}
                            >
                                {({ remove, removeButtonVisible, ...arrayProps }: any) => (
                                    <div className='employee-form-array-content'>
                                        <Input id={'value'}
                                               label={'Телефон'}
                                               {...arrayProps}/>
                                        {
                                            removeButtonVisible &&
                                            <IconButton size={'s'}
                                                        icon={CloseIcon}
                                                        styleType={'ghost'}
                                                        onClick={remove}
                                                        className={'employee-form-array-remove'}
                                            />
                                        }

                                    </div>
                                )}
                            </FormArray>
                        </div>

                        <div className='employee-form-array'>
                            <FormArray id={'emails'}
                                       addButton={<Button type={'button'}
                                                          size={'s'}
                                       >
                                           <p>Добавить</p>
                                       </Button>}
                                       arrayInitialValues={phoneInitialValue}
                                       {...formProps}
                            >
                                {({ remove, removeButtonVisible, ...arrayProps }: any) => (
                                    <div className='employee-form-array-content'>
                                        <Input id={'value'} label={'Email'} {...arrayProps}/>
                                        {
                                            removeButtonVisible &&
                                            <IconButton size={'s'}
                                                        icon={CloseIcon}
                                                        styleType={'ghost'}
                                                        onClick={remove}
                                                        className={'employee-form-array-remove'}
                                            />
                                        }

                                    </div>
                                )}
                            </FormArray>
                        </div>

                        {apps && <CheckboxGroup id={'claims'}
                                                label={'Права доступа'}
                                                {...formProps}
                        >
                            {
                                apps.filter(a => accessApps.includes(a.id)).map(app => (
                                    <div key={app.id} className='employee-form-access-block'>
                                        <Text>{app.title}</Text>
                                        {app.claims.map(claim => (
                                            <Checkbox key={claim.id}
                                                      id={claim.id}
                                                      label={claim.title}
                                                      className='employee-form-access-checkbox'
                                            />
                                        ))}
                                    </div>
                                ))
                            }
                        </CheckboxGroup>}

                        <Button type={'submit'}>Сохранить</Button>
                    </div>
                )}
            </Form>
        </div>
    );
};

export default EmployeeForm;