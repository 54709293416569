import React from 'react';
import { H2 } from 'vision.kit';

import { callApi } from '../../api';
import Page from '../../common/Page';

import OrganizationForm from '../../components/organizations/OrganizationForm';

const NewOrganizationScreen = ({ history }: any) => {
    const save = async (payload: any) => {
        let id = await callApi(`organizations`, 'POST', {
            ...payload,
            apps_access: [ 'structure', 'ats' ],
            apps_claims: []
        });
        history.push(`/organizations/${id}`);
    };

    return (
       <Page>
           <H2>Новая организация</H2>
           <OrganizationForm save={save}/>
       </Page>
    );
};

export default NewOrganizationScreen;