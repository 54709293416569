import React from 'react';
import { Link } from 'react-router-dom';
import { H1, Button } from 'vision.kit';

import { useApi } from '../../api';
import EmployeesList from '../../components/employees/EmployeesList';

import Page from '../../common/Page';

import './EmployeesListScreen.scss';
import { isAdmin } from '../../utils/access/isAdmin';

const EmployeesListScreen = () => {
    //@ts-ignore
    let profile = JSON.parse(localStorage.getItem(`hr.vision.profile`));
    const [ loading, employees ] = useApi(`employees?organizationId=${profile.organization_id}`);

    return (
        <Page>
            <div className='employee-list-header'>
                <H1>{profile.organization_title}</H1>

                {isAdmin() && <Button size={'s'}
                        container={<Link to={'/employees/new'}/>}
                >
                    Добавить сотрудника
                </Button>}
            </div>
            {!loading && <EmployeesList employees={employees}/>}
        </Page>
    );
};

export default EmployeesListScreen;