import React, { useContext } from 'react';
import { H1, Text, Notification, NotificationsContext } from 'vision.kit';

import './RestorePasswordScreen.scss';
import PasswordRestoreForm from '../components/profile/PasswordRestoreForm';
import { parseQueryString } from '../utils/parseQueryString';
import { callApi } from '../api';
import userManager from '../app/userManager';

const PasswordNotMatchNotification = () => (
    <Notification error>
        <Text>Введенные пароли не совпадают</Text>
    </Notification>
);

const RestorePasswordScreen = ({ location }: any) => {
    let params = parseQueryString(location.search);
    console.log(params);
    const token = params['token'];
    const userId = params['userId'];

    let { showNotification } = useContext(NotificationsContext);

    const save = async (payload: any) => {
        if (payload.password !== payload.repeat_password) {
            showNotification(<PasswordNotMatchNotification/>);
            return;
        }

        await callApi(`${userId}/password/reset`, 'POST', {
            token: token,
            password: payload.password
        });

        userManager.signinRedirect();
    };

    return (
        <div className='restore-password-screen'>
            <H1>HR Vision</H1>

            <PasswordRestoreForm save={save}/>
        </div>
    );
};

export default RestorePasswordScreen;