import React from 'react';
import Form from '../../common/Form';
import { Input, Button, H4 } from 'vision.kit';

import './PasswordRestoreForm.scss';

const PasswordRestoreForm = ({ save }: any) => {
    const initialValues = {
        password: '',
        repeat_password: ''
    };

    return (
        <div className='password-restore-form'>
            <H4>Восстановление пароля</H4>

            <Form initialValues={initialValues} onSubmit={save}>
                {(formProps: any) => (
                    <div>
                        <Input id={'password'} type={'password'} label={'Новый пароль'} {...formProps}/>
                        <Input id={'repeat_password'} type={'password'} label={'Подтверждение пароля'} {...formProps}/>
                        <Button type={'submit'}>Установить новый пароль</Button>
                    </div>
                )}
            </Form>
        </div>
    );
};

export default PasswordRestoreForm;