import React from 'react';
import { Text, Description } from 'vision.kit';

import EmployeeAvatar from './EmployeeAvatar';
import ListItem from '../../common/ListItem';

import './EmployeeListItem.scss';

const Contacts = ({ employee }: any) => {
    const Phone = ({ phones }: any) => {
        if (!phones || phones.length === 0) {
            return <div/>;
        }

        return (
            <div>
                <Description>
                    {phones[0].value}
                </Description>
            </div>
        );
    };

    const Email = ({ emails }: any) => {
        if (!emails || emails.length === 0) {
            return <div/>;
        }

        return (
            <Description>
                {emails[0].value}
            </Description>
        );
    };

    return (
        <div>
            <Text>Контакты</Text>

            <Phone phones={employee.phones}/>
            <Email emails={employee.emails}/>
        </div>
    )
};

const EmployeeListItem = ({ employee }: any) => {
    if (!employee) {
        return null;
    }

    return (
        <ListItem link={`/employees/${employee.id}`}>
            <div className='employee-list-item-avatar'>
                <EmployeeAvatar employee={employee}
                                size={'l'}
                />
            </div>

            <div className='employee-list-item-content'>
                <div className='employee-list-item-personal'>
                    <p>{employee.surname} {employee.name}</p>

                    <Description>
                        {employee.position}
                    </Description>
                </div>

                <Contacts employee={employee}/>
            </div>
        </ListItem>
    );
};

export default EmployeeListItem;