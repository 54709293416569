import React from 'react';
import { Employee } from '../../models/employees/Employee';
import { Organization } from '../../models/organizations/Organization';
import EmployeesList from '../employees/EmployeesList';
import { Link } from 'react-router-dom';
import { Button } from 'vision.kit';

import './OrganizationEmployees.scss';

interface OrganizationEmployeesProps {
    employees: Employee[];
    organization: Organization;
}

const OrganizationEmployees = ({ employees, organization }: OrganizationEmployeesProps) => {
    return (
        <div>
            <div className='organization-employees-header'>
                <Button size={'s'}
                        container={<Link to={`/organizations/${organization.id}/employees/new`}/>}
                >
                    Добавить сотрудника
                </Button>
            </div>
            <EmployeesList employees={employees}/>
        </div>
    );
};

export default OrganizationEmployees;
