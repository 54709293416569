export const Apps = [
    {
        id: 'structure',
        title: 'Орг. структура',
        claims: [
            {
                id: 'structure_access',
                title: 'Есть доступ к орг. структуре'
            },
            {
                id: 'structure_read',
                title: 'Чтение орг. структуры'
            },
            {
                id: 'structure_manage',
                title: 'Управление орг. структурой'
            }
        ]
    },
    {
        id: 'crm',
        title: 'CRM',
        claims: [
            {
                id: 'crm_access',
                title: 'Есть доступ к CRM'
            },
            {
                id: 'crm_customers_manage',
                title: 'Создание и удаление клиентов'
            },
            {
                id: 'crm_customer_listing',
                title: 'Просмотр списка клиентов'
            }
        ]
    },
    {
        id: 'ats',
        title: 'ATS',
        claims: [
            {
                id: 'ats_access',
                title: 'Есть доступ к ATS'
            },
            {
                id: 'ats_candidates_manage',
                title: 'Управление кандидатами'
            },
            {
                id: 'ats_vacancies_manage',
                title: 'Управление вакансиями'
            }
        ]
    }
];