import React from 'react';
import Form from '../../common/Form';
import { Input, Button } from 'vision.kit';

import './OrganizationForm.scss';

interface OrganizationFormProps {
    save: (payload: any) => void;
}

const OrganizationForm = ({ save }: OrganizationFormProps) => {
    const initialValues = {
        title: ''
    };

    const onSubmit = async (payload: any) => {
        await save(payload);
    };

    return (
        <Form initialValues={initialValues} onSubmit={onSubmit}>
            {(formProps: any) => (
                <div className='organization-form'>
                    <Input id={'title'} label={'Название'} {...formProps}/>
                    <Button type={'submit'}>Сохранить</Button>
                </div>
            )}
        </Form>
    );
};

export default OrganizationForm;