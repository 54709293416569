import React from 'react';
import Page from '../../common/Page';
import SelectableGroup from '../../common/selectable/SelectableGroup';
import SelectableItem from '../../common/selectable/SelectableItem';

import { Text } from 'vision.kit';

import './CalendarEditScreen.scss';

const CalendarEditScreen = () => {
    const calculateDays = () => {
        const days = [];

        for (let i = 0; i < 7; ++i) {
            let hours = [];
            for (let j = 7; j < 24; ++j) {
                const hour = {
                    id: `${i}-${j}`,
                    value: j,
                    display: `${j}:00`
                };
                hours.push(hour);
            }

            const day = {
                id: i,
                hours: hours
            };

            days.push(day);
        }

        return days;
    };

    const days = calculateDays();

    const handleSelected = (payload: any) => {
        console.log(payload);
    };

    return (
        <Page>
            Редактирование расписания

            <SelectableGroup className='schedule-week'
                             onSelected={handleSelected}
            >
                {
                    days.map(day => (
                        <div key={day.id}>
                            {
                                day.hours.map(hour => (
                                    <SelectableItem key={hour.id}
                                                    id={hour.id}
                                                    className='schedule-week-hour schedule-week-cell'
                                                    selectedClassName='schedule-week-hour-selected'
                                    >
                                        <Text>{hour.display}</Text>
                                    </SelectableItem>
                                ))
                            }
                        </div>
                    ))
                }
            </SelectableGroup>
        </Page>
    );
};

export default CalendarEditScreen;