import React from 'react';
import { Route, Switch } from 'react-router';
import NotFoundScreen from '../screens/NotFoundScreen';
import EmployeesListScreen from '../screens/employees/EmployeesListScreen';
import EmployeeScreen from '../screens/employees/EmployeeScreen';
import NewEmployeeScreen from '../screens/employees/NewEmployeeScreen';
import ActivationScreen from '../screens/employees/ActivationScreen';
import EmployeeEditScreen from '../screens/employees/EmployeeEditScreen';
import CalendarScreen from '../screens/profile/CalendarScreen';
import CalendarEditScreen from '../screens/profile/CalendarEditScreen';
import OrganizationsListScreen from '../screens/organizations/OrganizationsListScreen';
import ProtectedRoute from '../common/ProtectedRoute';
import LoginRedirect from '../screens/LoginRedirect';
import OrganizationScreen from '../screens/organizations/OrganizationScreen';
import RestorePasswordScreen from '../screens/RestorePasswordScreen';
import SilentRenewScreen from '../screens/SilentRenewScreen';
import NewOrganizationScreen from '../screens/organizations/NewOrganizationScreen';
import NewEmployeeOrganizationScreen from '../screens/organizations/NewEmployeeOrganizationScreen';

const Routes = () => (
    <Switch>
        <ProtectedRoute exact path={'/'} component={EmployeesListScreen}/>
        <Route exact path={'/signed_in'} component={LoginRedirect} />
        <Route exact path={'/password/restore'} component={RestorePasswordScreen} />
        <Route exact path={'/silent_renew'} component={SilentRenewScreen} />
        <ProtectedRoute exact path={'/employees'} component={EmployeesListScreen}/>
        <ProtectedRoute exact path={'/employees/new'} component={NewEmployeeScreen}/>
        <ProtectedRoute exact path={'/employees/:id/edit'} component={EmployeeEditScreen}/>
        <ProtectedRoute exact path={'/employees/:id'} component={EmployeeScreen}/>
        <ProtectedRoute exact path={'/organizations'} component={OrganizationsListScreen}/>
        <ProtectedRoute exact path={'/organizations/new'} component={NewOrganizationScreen}/>
        <ProtectedRoute exact path={'/organizations/:id/employees/new'} component={NewEmployeeOrganizationScreen}/>
        <ProtectedRoute exact path={'/organizations/:id'} component={OrganizationScreen}/>
        <ProtectedRoute exact path={'/calendar'} component={CalendarScreen}/>
        <ProtectedRoute exact path={'/calendar/edit'} component={CalendarEditScreen}/>
        <Route exact path={'/users/activation/:id'} component={ActivationScreen}/>
        <Route component={NotFoundScreen}/>
    </Switch>
);

export default Routes;