import React, { cloneElement } from 'react';
import { Link } from 'react-router-dom';

import './ListItem.scss';

const ListItem = ({ link, children }: any) => {
    const container = link ? <Link to={link}/> : <div/>;
    const props = {
        className: 'list-item'
    };

    return cloneElement(container, props, children);
};

export default ListItem;