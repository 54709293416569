import { UserManager } from 'oidc-client';
import config from '../config';

const authConfig = {
    client_id: 'hr.vision',
    redirect_uri: `${config.REDIRECT_URI}/structure/signed_in`,
    response_type: 'code',
    scope: 'openid profile',
    authority: config.AUTHORITY,
    post_logout_redirect_uri: `${config.REDIRECT_URI}/structure`,
    silent_redirect_uri: `${config.REDIRECT_URI}/structure/silent_renew`,
    automaticSilentRenew: true,
    loadUserInfo: true,
    filterProtocolClaims: true,
    accessTokenExpiringNotificationTime: 4
};

const userManager = createUserManager(authConfig);

export default userManager;

function createUserManager(config: any) {
    return new UserManager(config);
}