import React from 'react';
import userManager from '../app/userManager';

const SilentRenewScreen = () => {
    userManager.signinSilentCallback()
        .catch((err) => {
            console.log(err);
        });

    return (
        <div>
            Silent renew
        </div>
    )
};

export default SilentRenewScreen;