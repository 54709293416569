import React from 'react';

import { callApi } from '../api';
import userManager from '../app/userManager';

const LoginRedirect = ({ history }: any) => {
    userManager.signinRedirectCallback()
        .then(async () => {
            const profile = await callApi('employees/profile');
            sessionStorage.setItem('hr.vision.profile', JSON.stringify(profile));

            history.push('/');
        })
        .catch((error) => {
            console.log(error);
        });

    return (
        <div>
            <span>Login redirect</span>
        </div>
    )
};

export default LoginRedirect;
