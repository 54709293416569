import React from 'react';
import Page from '../common/Page';

const NotFoundScreen = () => {
    return (
        <Page>
            NOT FOUND
        </Page>
    )
};

export default NotFoundScreen;