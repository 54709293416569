import React from 'react';

import { callApi, useApi } from '../../api';
import EmployeeProfile from '../../components/employees/EmployeeProfile';

import Page from '../../common/Page';

import './EmployeeScreen.scss';

const EmployeeScreen = ({ history, match }: any) => {
    const id = match.params.id;

    const [ loading, employee ] = useApi(`employees/${id}`);

    const remove = async () => {
        await callApi(`employees/${id}`, 'DELETE');
        history.push('/');
    };

    return (
        <Page>
            {!loading && <EmployeeProfile employee={employee} remove={remove}/>}
        </Page>
    );
};

export default EmployeeScreen;