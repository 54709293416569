import React from 'react';
import { Route } from 'react-router-dom';

import userManager from '../app/userManager';
import { getAccessToken } from '../utils/authentication/getAccessToken';

interface ProtectedRouteProps {
    component: any;
    exact?: boolean;
    path?: string;
}

const ProtectedRoute = ({ component: Component, ...rest }: ProtectedRouteProps) => {
    const isAuthenticated = () => {
        return getAccessToken();
    };

    return (
        <Route {...rest} render={(props) => {
            if (!isAuthenticated())

                userManager.signinSilentCallback()
                    .catch(() => {
                        userManager.signinRedirect();
                    });

            return <Component {...props} />;
        }} />
    );
};

export default ProtectedRoute;