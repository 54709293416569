import React from 'react';
import { Input, Button } from 'vision.kit';

import Form from '../../common/Form';

const ActivationForm = ({ employee, save }: any) => {
    return (
        <Form initialValues={employee} onSubmit={save}>
            {(formProps: any) => (
                <div>
                    <Input id={'surname'} label={'Фамилия'} {...formProps}/>
                    <Input id={'name'} label={'Имя'} {...formProps}/>
                    <Input id={'email'} label={'Email'} {...formProps}/>
                    <Input id={'password'} label={'Пароль'} type={'password'} {...formProps}/>
                    <Input id={'confirm_password'} label={'Подтвердите пароль'} type={'password'} {...formProps}/>
                    <Input id={'phone'} label={'Телефон'} {...formProps}/>

                    <Button type={'submit'}>Сохранить</Button>
                </div>
            )}
        </Form>
    );
};

export default ActivationForm;