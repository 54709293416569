import React from 'react';
import Header from './Header';

import './Page.scss';

interface PageProps {
    children?: React.ReactNode;
}

const Page = ({ children }: PageProps) => {
    return (
        <div className='page'>
            <Header/>

            <div className='page-content'>
                {children}
            </div>
        </div>
    )
};

export default Page;