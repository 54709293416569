import React from 'react';
import { Avatar } from 'vision.kit';

import { Employee } from '../../models/employees/Employee';

interface EmployeeAvatarProps {
    employee: Employee;
    size: string | number;
}

const EmployeeAvatar = ({ employee, size }: EmployeeAvatarProps) => {
    const char = employee.surname[0] + employee.name[0];

    return (
        <Avatar char={char}
                size={size}
                shape={'rounded'}
                backgroundColor={'#4059AD'}
        />
    );
};

export default EmployeeAvatar;