import React from 'react';
import { callPassportApi, usePassportApi } from '../../api';
import { H2, H4 } from 'vision.kit';
import ActivationForm from '../../components/employees/ActivationForm';

import './ActivationScreen.scss';
import config from '../../config';

const ActivationScreen = ({ history, match }: any) => {
    const id = match.params.id;
    console.log(config.AUTHORITY);
    let employee = usePassportApi(`users/activation/${id}`);

    const save = async (payload: any) => {
        await callPassportApi(`users/activation/${id}`, 'POST', payload);
        history.push('/');
    };

    return (
        <div className='activation-screen'>
            <H2>HR Vision</H2>
            <H4>Активация аккаунта</H4>

            <div className='activation-screen-content'>
                {employee && <ActivationForm employee={employee} save={save}/>}
            </div>
        </div>
    );
};

export default ActivationScreen;
