import React, { useContext, useEffect, useRef } from 'react';
import classnames from 'classnames';
import SelectableContext from './SelectableContext';

const buildClasses = (id: any, className: any, selectedClassName: any, state: any) => {
    return classnames(
        className,
        {
            [`${selectedClassName}`]: state.selected
                .filter((s: any) => s === id).length > 0 || state.selecting.filter((s: any) => s === id).length > 0
        }
    );
};

const SelectableItem = ({ id, className, children, selectedClassName }: any) => {
    const { state, dispatch } = useContext(SelectableContext);

    const itemRef: any = useRef(null);

    const dispatchOffset = (command: any) => {
        if (itemRef.current === null || dispatch === undefined)
            return;

        dispatch({
            type: command,
            payload: {
                id: id,
                x: itemRef.current.offsetLeft,
                y: itemRef.current.offsetTop,
                width: itemRef.current.offsetWidth,
                height: itemRef.current.offsetHeight,
            }
        });
    };

    if (itemRef && itemRef.current && state.items[id].x !== itemRef.current.offsetLeft) {
        dispatchOffset('item-reload');
    }

    useEffect(() => {
        if (itemRef) {
            //dispatchOffset('item-loaded');
        }
    }, [ itemRef ]);

    const handleClick = () => {
        if (dispatch) {
            dispatch({ type: 'item-click', payload: { id: id } });
        }
    };

    return (
        <div className={buildClasses(id, className, selectedClassName, state)}
             onClick={handleClick}
             ref={itemRef}
        >
            {children}
        </div>
    );
};

export default SelectableItem;