import React from 'react';
import { Form as FormikForm, Formik } from 'formik';

interface FormProps {
    initialValues: any;
    validationSchema?: any;
    enableReinitialize?: boolean;
    onSubmit: (payload: any) => void;
    children: (props: any) => React.ReactNode;
}

const Form = ({ initialValues, validationSchema, onSubmit, children }: FormProps) => {
    const getExtendedProps = (props: any) => {
        return {
            ...props,
            onChange: (id: any, value: any) => {
                let e = {
                    type: 'change',
                    target: {
                        id: id,
                        value: value
                    }
                };
                if (props.handleChange) {
                    props.handleChange(e, value);
                }
            },
            getInitialValue: (id: string) => {
                return initialValues[id] || '';
            }
        }
    };

    return (
        <Formik initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
        >
            {(formProps: any) => (
                <FormikForm>
                    {children(getExtendedProps(formProps))}
                </FormikForm>
            )}
        </Formik>
    )
};

export default Form;