import React, { useState } from 'react';
import classnames from 'classnames';
import moment from 'moment';

import Page from '../../common/Page';

import './CalendarScreen.scss';
import { Description } from 'vision.kit';

const toEuWeekDay = (day: number): number => {
    if (day === 0) {
        return 6;
    }

    return day - 1;
};

const CalendarScreen = () => {
    const calculateDays = (events: any[]) => {
        let days = [];
        let now = moment();
        let start = 0 - toEuWeekDay(now.day());

        for (let i = start; i < start + 7; ++i) {
            let date = moment(now).add(i, 'days');

            let hours = [];
            for (let j = 7; j < 23; ++j) {
                let hour = {
                    id: `${i}-${j}`,
                    event: events.find(e => moment(e.date).isSame(date, 'day') && moment(e.date).hour() === j)
                };

                hours.push(hour)
            }

            let day = {
                id: i,
                date: date,
                title: date.format('DD.MM'),
                today: date.isSame(moment(), 'day'),
                hours: hours
            };

            days.push(day);
        }

        return days;
    };

    const weekDays = [ 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС' ];

    const events = [
        {
            id: '1',
            title: 'Артамонов Иван',
            date: new Date('07.28.2020 13:00')
        },
        {
            id: '2',
            title: 'Ларинова Инна',
            date: new Date('07.29.2020 17:00')
        },
        {
            id: '3',
            title: 'Максименко Григорий',
            date: new Date('07.30.2020 11:00')
        },
        {
            id: '4',
            title: 'Орлова Екатерина',
            date: new Date('07.30.2020 16:00')
        },
        {
            id: '5',
            title: 'Меняева Татьяна',
            date: new Date('08.02.2020 10:00')
        }
    ];

    let [ days, /*setDays*/ ] = useState(calculateDays(events));

    return (
        <Page>
            Мой календарь

            <div className='calendar'>
                {days.map((day: any) => (
                    <div key={day.id}
                         className={classnames({ 'calendar-today': day.today })}
                    >
                        {day.title}
                    </div>
                ))}

                {weekDays.map((day: any) => (
                    <div key={day}>
                        <Description>{day}</Description>
                    </div>
                ))}

                {
                    days.map((day: any) => (
                        <div key={day.id}>
                            {
                                day.hours.map((hour: any) => (
                                    <div key={hour.id} className='calendar-hour'>
                                        {hour.event && <span>{hour.event.title}</span>}
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        </Page>
    );
};

export default CalendarScreen;