import React, { cloneElement } from 'react';
import { FieldArray } from 'formik';

const FormArray = ({ id, children, addButton, arrayInitialValues, initialValues, ...formProps }: any) => {
    let arrayId = id;

    const add = (arrayHelpers: any, initialValue: any) => {
        arrayHelpers.insert(formProps.values[id].length, initialValue)
    };

    const getExtendedProps = (arrayHelpers: any, index: number) => {
        const remove = () => {
            if (formProps.values[id].length > 1) {
                arrayHelpers.remove(index);
            }
        };

        return {
            ...formProps,
            remove: remove,
            removeButtonVisible: formProps.values[id].length > 1,
            onChange: (id: any, value: any) => {
                let e = {
                    type: 'change',
                    target: {
                        id: `${arrayId}.${index}.${id}`,
                        value: value
                    }
                };
                if (formProps.handleChange) {
                    formProps.handleChange(e, value);
                }
            },
            getInitialValue: (id: string) => {
                if (initialValues[arrayId][index]) {
                    return initialValues[arrayId][index][id];
                }

                return '';
            }
        }
    };

    if (!formProps.values[id] || formProps.values[id].length === 0) {
        formProps.values[id] = [];
        formProps.values[id].push(arrayInitialValues);
    }

    return (
        <FieldArray name={id}>
            {(arrayHelpers) => (
                <div>
                    {
                        formProps.values[id].map((value: any, index: number) => (
                            <div key={index}>
                                {children(getExtendedProps(arrayHelpers, index))}
                            </div>
                        ))
                    }

                    {cloneElement(addButton, { onClick: () => {
                            add(arrayHelpers, arrayInitialValues);
                        }
                    })}
                </div>
            )}
        </FieldArray>
    );
};

export default FormArray;