const StructureAccess = "structure_access";
const CrmAccess = "crm_access";
const AtsAccess = "ats_access";
const StructureManage = "structure_manage";
const StructureRead = 'structure_read';
const CustomersManage = 'crm_customers_manage';

export {
    StructureAccess,
    CrmAccess,
    AtsAccess,
    StructureManage,
    StructureRead,
    CustomersManage
}