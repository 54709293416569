export const OrganizationClaims = [
    {
        id: 'structure',
        title: 'Орг. структура',
        claims: [
            {
                id: 'structure_organizations_manage',
                title: 'Управление организациями'
            }
        ]
    },
    {
        id: 'crm',
        title: 'CRM',
        claims: []
    },
    {
        id: 'ats',
        title: 'ATS',
        claims: [
            {
                id: 'ats_candidates_manage',
                title: 'Управление кандидатами'
            },
            {
                id: 'ats_vacancies_manage',
                title: 'Управление вакансиями'
            },
            {
                id: 'ats_candidates_manage_interviews',
                title: 'Управление записями собеседований'
            },
            {
                id: 'ats_candidates_manage_vacancies',
                title: 'Управление вакансиями кандидатов'
            }
        ]
    }
];