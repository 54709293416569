import React from 'react';
import { callApi } from '../../api';
import Page from '../../common/Page';
import EmployeeForm from '../../components/employees/EmployeeForm';
import { Apps } from '../../models/Apps';
import { H3 } from 'vision.kit';

const NewEmployeeOrganizationScreen = ({ match, history }: any) => {
    const id = match.params.id;
    const claims = Apps;

    const addEmployee = async (data: any) => {
        await callApi(`employees`, 'POST', {
            ...data,
            organization_id: id
        });
        history.push(`/organizations/${id}`);
    };

    return (
        <Page>
            <H3>Новый сотрудник</H3>

            {claims && <EmployeeForm apps={claims} save={addEmployee}/>}
        </Page>
    );
};

export default NewEmployeeOrganizationScreen;