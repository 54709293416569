import React from 'react';

import OrganizationsListItem from './OrganizationsListItem';
import { Organization } from '../../models/organizations/Organization';

interface OrganizationsListProps {
    organizations: Organization[];
}

const OrganizationsList = ({ organizations }: OrganizationsListProps) => {
    return (
        <div>
            {organizations.map(organization => (
                <OrganizationsListItem key={organization.id} organization={organization}/>
            ))}
        </div>
    );
};

export default OrganizationsList;