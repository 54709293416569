import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { Avatar, Button, Description, Text } from 'vision.kit';

import './Header.scss';
import useClickOutside from './useClickOutside';
import userManager from '../app/userManager';
import { getUserClaims } from '../utils/getUserClaims';
import { AtsAccess, CrmAccess, StructureAccess } from '../models/Claims';
import config from '../config';
import ProtectedComponent from './ProtectedComponent';

const SubNavigation = {
    Main: '/',
    Employees: '/employees',
    Organizations: '/organizations'
};

interface NavigationLinkProps {
    url?: string;
    content: string;
    active?: boolean;
}

const NavigationLink = ({ url, content, active }: NavigationLinkProps) => (
    <a href={url}
       className={classnames('header-navigation-link', { 'header-link-active': active })}
    >
        {content}
    </a>
);

interface SubNavigationLinkProps {
    to: string;
    content: string;
    active: boolean;
}

const SubNavigationLink = ({ to, content, active }: SubNavigationLinkProps) => (
    <Link to={to}
          className={classnames(
              'header-sub-navigation-link',
              { 'header-link-active': active }
          )}
    >
        {content}
    </Link>
);

const AvatarMenu = ({ visible }: any) => {
    if (!visible) {
        return null;
    }

    const logout = async () => {
        await userManager.signoutRedirect();
    };

    return (
        <div className='header-avatar-menu'>
            <div className='header-avatar'>
                <Text>Андрей Матвеев</Text>
                <Avatar char={'AM'}
                        size={'s'}
                        shape={'rounded'}
                        backgroundColor={'#4059AD'}
                />
            </div>

            <Button size={'s'} onClick={logout}>Выход</Button>
        </div>
    );
};

const Header = () => {
    let currentPath = window.location.pathname.replace('/structure', '');

    let claims = getUserClaims();
    let [ menuVisible, setMenuVisible ] = useState(false);
    let avatarRef = useRef(null);
    useClickOutside(avatarRef, () => setMenuVisible(false));

    return (
        <div className='header'>
            <div className='header-content'>
                <div className='header-navigation'>
                    <Link to={'/'} className='header-navigation-home'>
                        <h4>HR Vision</h4>
                    </Link>

                    <div className='header-navigation-links'>
                        {claims.includes(StructureAccess) && <NavigationLink url={config.STRUCTURE}
                                                                             content={'Орг. структура'}
                                                                             active
                        />}

                        {claims.includes(CrmAccess) && <NavigationLink url={config.CRM}
                                                                       content={'CRM'}
                        />}

                        {claims.includes(AtsAccess) && <NavigationLink url={config.ATS}
                                                                       content={'ATS'}
                        />}
                    </div>
                </div>

                <div className='header-avatar-menu-container'
                     ref={avatarRef}
                >
                    <Avatar char={'AM'}
                            size={'s'}
                            backgroundColor={'#4059AD'}
                            shape={'rounded'}
                            onClick={() => setMenuVisible(true)}
                    />

                    <AvatarMenu visible={menuVisible}/>
                </div>
            </div>

            <div className='header-sub-navigation'>
                <div className='header-content'>
                    <div className='header-navigation'>
                        <SubNavigationLink to={SubNavigation.Employees}
                                           content={'СОТРУДНИКИ'}
                                           active={!currentPath || currentPath.startsWith(SubNavigation.Employees)}
                        />

                        <ProtectedComponent claims={['structure_organizations_manage']}>
                            <SubNavigationLink to={SubNavigation.Organizations}
                                               content={'КОМПАНИИ'}
                                               active={currentPath.startsWith(SubNavigation.Organizations)}
                            />
                        </ProtectedComponent>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;