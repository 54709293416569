import React from 'react';

import EmployeeListItem from './EmployeeListItem';

const EmployeesList = ({ employees }: any) => {
    if (!employees) {
        return null;
    }

    return (
        <div>
            {
                employees.map((employee: any) => (
                    <EmployeeListItem key={employee.id} employee={employee}/>
                ))
            }
        </div>
    );
};

export default EmployeesList;