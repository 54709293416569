import React from 'react';
import { Button } from 'vision.kit';

import Page from '../../common/Page';
import { useApi } from '../../api';
import OrganizationsList from '../../components/organizations/OrganizationsList';

import './OrganizationsListScreen.scss';
import { Link } from 'react-router-dom';

const OrganizationsListScreen = () => {
    let [ loading, organizations ] = useApi('organizations');

    return (
        <Page>
            <div className='organizations-list-screen-header'>
                <p>Все организации</p>

                <Button container={<Link to={'organizations/new'}/>}>
                    Новая компания
                </Button>
            </div>

            {!loading && <OrganizationsList organizations={organizations}/>}
        </Page>
    );
};

export default OrganizationsListScreen;