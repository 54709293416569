import React from 'react';
import { Organization } from '../../models/organizations/Organization';
import { H4 } from 'vision.kit';
import { Link } from 'react-router-dom';

import './OrganizationsListItem.scss';

interface OrganizationsListItemProps {
    organization: Organization;
}

const OrganizationsListItem = ({ organization }: OrganizationsListItemProps) => {
    return (
        <Link to={`/organizations/${organization.id}`} className='organizations-list-item'>
            <H4>{organization.title}</H4>
        </Link>
    );
};

export default OrganizationsListItem;