import { useEffect, useReducer, useState } from 'react';
import axios, { Method } from 'axios';
import { getAccessToken } from '../utils/authentication/getAccessToken';
import config from '../config';
import userManager from '../app/userManager';

const API = config.APP_API;

const useApiReducer = (state: any, action: any) => {
    switch (action.type) {
        case 'start': {
            return {
                ...state,
                loading: true
            }
        }
        case 'loaded': {
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        }
        default:
            return state;
    }
};

const useApi = (url: string, initialData: any = undefined) => {
    let [ state, dispatch ] = useReducer(useApiReducer, {
        data: initialData,
        loading: true
    });

    const method: Method = 'GET';

    useEffect(() => {
        dispatch({ type: 'start' });

        (async () => {
            const token = getAccessToken();
            const settings = {
                method: method,
                url: `${API}/${url}`,
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            };

            try {
                let response = await axios.request(settings);

                dispatch({ type: 'loaded', payload: response.data });
            } catch (e) {
                if (e.response.status === 401) {
                    userManager.signinRedirect();
                }
            }
        })();
    }, [ url ]);

    return [ state.loading, state.data ];
};

const callApi = async (url: string, method: Method = 'GET', payload: any = {}) => {
    const token = getAccessToken();

    const settings = {
        method: method,
        url: `${API}/${url}`,
        data: payload,
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    };

    let response = await axios.request(settings);
    return response.data;
};

const passportApi = config.AUTHORITY;

const usePassportApi = (url: string) => {
    let [ data, setData ] = useState(null);
    const method: Method = 'GET';

    useEffect(() => {
        (async () => {
            const settings = {
                method: method,
                url: `${passportApi}/${url}`,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            };


            let response = await axios.request(settings);
            setData(response.data);
        })();
    }, [ url ]);

    return data;
};

const callPassportApi = async (url: string, method: Method = 'GET', payload: any = {}) => {
    const settings = {
        method: method,
        url: `${passportApi}/${url}`,
        data: payload,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    };

    let response = await axios.request(settings);
    return response.data;
};

export { useApi, callApi, usePassportApi, callPassportApi };
