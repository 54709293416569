import React, { useState } from 'react';
import { H4, Tabs, TabItem } from 'vision.kit';

import { Organization } from '../../models/organizations/Organization';
import OrganizationClaimsForm from './OrganizationClaimsForm';
import { callApi } from '../../api';
import { Employee } from '../../models/employees/Employee';
import OrganizationEmployees from './OrganizationEmployees';

interface OrganizationCardProps {
    organization: Organization;
    employees: Employee[];
}

const OrganizationCard = ({ organization, employees }: OrganizationCardProps) => {
    let [ tab, setTab ] = useState('employees');

    const save = async (payload: any) => {
        await callApi(`organizations/${organization.id}`, 'POST', {
            ...organization,
            ...payload
        });
    };

    return (
        <div>
            <H4>{organization.title}</H4>

            <Tabs selected={tab} onChange={setTab}>
                <TabItem value={'employees'}>Сотрудники</TabItem>
                <TabItem value={'access'}>Права доступа</TabItem>
            </Tabs>

            <div>
                {tab === 'access' && <OrganizationClaimsForm organization={organization} save={save}/>}
                {tab === 'employees' && <OrganizationEmployees employees={employees} organization={organization}/>}
            </div>
        </div>
    );
};

export default OrganizationCard;